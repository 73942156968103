(function (root, factory) {
        if (typeof define === 'function' && define.amd) {
            // AMD. Register as an anonymous module.
            define([], factory);
        } else if (typeof module === 'object' && module.exports) {
            // Node. Does not work with strict CommonJS, but
            // only CommonJS-like environments that support module.exports,
            // like Node.
            module.exports = factory();
        } else {
            // Browser globals (root is window)
            root.returnExports = factory();
        }
    }(typeof self !== 'undefined' ? self : this, function () {
        // Just return a value to define the module export.
        // This example returns an object, but the module
        // can return a function as the exported value.
        'use strict';
        var skins = {};
         skins['wysiwyg.viewer.skins.displayers.LinkBarItemNoBGSkin'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {},
      [
        "div",
        "image",
        [],
        {}
      ]
    ]
  ],
  "exports": {
    "image": {
      "skin": "skins.core.ImageSkin"
    }
  },
  "css": {
    "%link": "cursor:pointer;"
  }
}
 skins['wysiwyg.viewer.skins.displayers.LinkBarItemSkin'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {},
      [
        "span",
        "image",
        [],
        {}
      ]
    ]
  ],
  "exports": {
    "image": {
      "skin": "skins.core.ImageSkin"
    }
  },
  "params": {
    "bg": "BG_COLOR_ALPHA",
    "rd": "BORDER_RADIUS",
    "bgh": "BG_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "bg": "color_11",
    "rd": "100%",
    "bgh": "color_11"
  },
  "css": {
    "%link": "cursor:pointer;",
    "%image": "z-index:10;",
    "%imageBG": "overflow:hidden;background-color:[bg];[rd]  position:relative !important;top:0;left:0;",
    "%imageBG:hover": "background-color:[bgh];"
  }
}
 skins['wysiwyg.viewer.skins.LinkBarNoBGSkin'] = {
  "react": [
    [
      "ul",
      "itemsContainer",
      [],
      {}
    ]
  ],
  "exports": {
    "imageItem": {
      "skin": "wysiwyg.viewer.skins.displayers.LinkBarItemNoBGSkin",
      "styleGroup": "displayer"
    }
  },
  "css": {
    "%[data-is-responsive~=\"false\"] %itemsContainer": "position:absolute;width:100%;height:100%;white-space:nowrap;",
    "%[data-is-responsive~=\"false\"][data-state~=\"mobileView\"] %itemsContainer": "position:absolute;width:100%;height:100%;white-space:normal;",
    "%[data-is-responsive~=\"true\"]": "display:table;",
    "%[data-is-responsive~=\"true\"] %itemsContainer": "display:-webkit-box;display:-webkit-flex;display:flex;",
    "%itemsContainer > li:last-child": "margin:0 !important;",
    "% a": "display:block;height:100%;"
  }
}

        return skins;
    }));