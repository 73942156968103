define(['linkBar/linkBar/linkBar', 'linkBar/linkBarItem/linkBarItem', 'skins', 'linkBar/skins/skins.json', 'componentsCore'],
    function (linkBar, linkBarItem, skinsPackage, skinsJson, componentsCore) {
        'use strict';

        const linkBarComp = {
            linkBar,
            linkBarItem
        };

        componentsCore.compRegistrar
            .register('wysiwyg.viewer.components.LinkBar', linkBarComp.linkBar)
            .register('wysiwyg.viewer.components.LinkBarItem', linkBarComp.linkBarItem);

        skinsPackage.skinsMap.addBatch(skinsJson);

        return linkBarComp;
    });
