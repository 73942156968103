define(['lodash', 'componentsCore', 'coreUtils', 'prop-types', 'santa-components'],
    function (_, componentsCore, coreUtils, PropTypes, santaComponents) {
        'use strict';

        const linkRenderer = coreUtils.linkRenderer;

        function createImageDataFromCompData(compData) {
            return _.assign({}, compData, {alt: compData.title});
        }

        /**
     * @class components.LinkBarItem
     * @extends {core.skinBasedComp}
     * @extends {utils.linkRenderer}
     */
        return {
            displayName: 'LinkBarItem',
            mixins: [componentsCore.mixins.skinBasedComp, componentsCore.mixins.createChildComponentMixin],

            propTypes: _.assign({
                linkRenderInfo: santaComponents.santaTypesDefinitions.Link.renderInfo.isRequired,
                rootNavigationInfo: santaComponents.santaTypesDefinitions.Component.rootNavigationInfo.isRequired,
                compData: santaComponents.santaTypesDefinitions.Component.compData.isRequired,
                compProp: santaComponents.santaTypesDefinitions.Component.compProp.isRequired,
                style: PropTypes.object,
                id: santaComponents.santaTypesDefinitions.Component.id.isRequired,
                isExperimentOpen: santaComponents.santaTypesDefinitions.isExperimentOpen.isRequired,
                parentId: PropTypes.string,
                itemStyle: PropTypes.object
            }, santaComponents.utils.santaTypesUtils.getSantaTypesFromPropTypes(santaComponents.components.Image.propTypes)),

            getSkinProperties() {
                const compData = this.props.compData;
                const compProps = this.props.compProp;
                const iconSize = compProps.iconSize;
                const refData = {
                    link: compData.link ? linkRenderer.renderLink(compData.link, this.props.linkRenderInfo, this.props.rootNavigationInfo) : {style: {cursor: 'default'}},
                    image: this.createChildComponent(
                        compData,
                        'core.components.Image',
                        'image', {
                            id: `${this.props.id}image`,
                            ref: 'image',
                            imageData: createImageDataFromCompData(compData),
                            containerWidth: iconSize,
                            containerHeight: iconSize,
                            displayMode: 'fill',
                            autoLayout: true,
                            wixImageLayout: this.props.isExperimentOpen('bv_wixImage'),
                            containerId: this.props.parentId,
                            style: _.assign({}, this.props.style, {position: 'absolute', width: iconSize, height: iconSize})
                        })
                };
                refData[''] = {
                    tagName: 'li',
                    style: {
                        width: compProps.iconSize,
                        height: compProps.iconSize,
                        marginBottom: this.props.itemStyle.marginBottom,
                        marginRight: this.props.itemStyle.marginRight,
                        display: this.props.itemStyle.display
                    }
                };

                return refData;
            }
        };
    });
